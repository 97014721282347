// cart
export const SET_CART = "cart/SET_CART_ID";
export const ADD_TO_CART = "cart/ADD_ITEM";
export const ADD_BUNDLE_TO_CART = "cart/ADD_BUNDLE_TO_CART";
export const ADD_PROMO_TO_CART = "cart/ADD_PROMO_TO_CART";
export const ADD_ITEMS_TO_CART = "cart/ADD_ITEMS_TO_CART";
export const REMOVE_FROM_CART = "cart/REMOVE_ITEM";
export const CLEAR_ITEMS = "cart/CLEAR_ITEMS";
export const SET_ITEMS = "cart/SET_ITEMS";
export const TOGGLE_LOADING = "cart/TOGGLE_LOADING";
export const COUPON_HASH = "cart/COUPON_HASH";

// Game
export const SET_DISPLAYED_GAME = "game/SET_DISPLAYED_GAME";
export const SET_GAMES = "game/SET_GAMES";

//checkout
export const SET_CHECKOUT = "checkout/SET_CHECKOUT";
export const SET_DESTINATION_DETAILS = "checkout/SET_DESTINATION_DETAILS";
export const SET_CHECKOUT_SUMMARY = "checkout/SET_CHECKOUT_SUMMARY";
export const SET_CHECKOUT_FIELD = "checkout/SET_CHECKOUT_FIELD";
export const SET_TOTAL_FIELD = "checkout/SET_TOTAL_FIELD";
export const SET_CART_ID = 'checkout/SET_CART_ID';

//contact
export const SET_CONTACT_FIRSTNAME = "contact/SET_CONTACT_FIRSTNAME";
export const SET_CONTACT_LASTNAME = "contact/SET_CONTACT_LASTNAME";
export const SET_CONTACT_EMAIL = "contact/SET_CONTACT_EMAIL";
export const SET_CONTACT_PHONE = "contact/SET_CONTACT_PHONE";
export const SET_CONTACT_COUNTRY = "contact/SET_CONTACT_COUNTRY";
export const SET_CONTACT_PRODUCT = "contact/SET_CONTACT_PRODUCT";
export const SET_CONTACT_SERIAL = "contact/SET_CONTACT_SERIAL";
export const SET_CONTACT_TYPE = "contact/SET_CONTACT_TYPE";
export const SET_CONTACT_SUBJECT = "contact/SET_CONTACT_SUBJECT";
export const SET_CONTACT_VERSION = "contact/SET_CONTACT_VERSION";
export const SET_CONTACT_DESCRIPTION = "contact/SET_CONTACT_DESCRIPTION";
export const SET_CONTACT_IP = "contact/SET_CONTACT_IP";
export const SET_CONTACT_CAPTCHA = "contact/SET_CONTACT_CAPTCHA";
export const SET_CONTACT_SUMMARY = "contact/SET_CONTACT_SUMMARY";
export const SET_CONTACT_FORM = "contact/SET_CONTACT_FORM";
