import * as types from "../../mutation-types";
import Vue from "vue";
import _ from 'lodash'

const state = {
  formData: {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    country: null,
    product: null,
    serial: null,
    type: null,
    subject: null,
    version: null,
    description: null,
    ip:null,
    captcha:null
  }
};


const mutations = {
  [types.SET_CONTACT_FIRSTNAME](state, data) {
    state.formData.firstname = data;
  },
  [types.SET_CONTACT_LASTNAME](state, data) {
    state.formData.lastname = data;
  },
  [types.SET_CONTACT_EMAIL](state, data) {
    state.formData.email = data;
  },
  [types.SET_CONTACT_PHONE](state, data) {
    state.formData.phone = data;
  },
  [types.SET_CONTACT_COUNTRY](state, data) {
    state.formData.country = data;
  },
  [types.SET_CONTACT_PRODUCT](state, data) {
    state.formData.product = data;
  },
  [types.SET_CONTACT_SERIAL](state, data) {
    state.formData.serial = data;
  },
  [types.SET_CONTACT_TYPE](state, data) {
    state.formData.type = data;
  },
  [types.SET_CONTACT_SUBJECT](state, data) {
    state.formData.subject = data;
  },
  [types.SET_CONTACT_VERSION](state, data) {
    state.formData.version = data;
  },
  [types.SET_CONTACT_DESCRIPTION](state, data) {
    state.formData.description = data;
  },
  [types.SET_CONTACT_IP](state, data) {
    state.formData.ip = data;
  },
  [types.SET_CONTACT_CAPTCHA](state, data) {
    state.formData.captcha = data;
  },
  [types.SET_CONTACT_SUMMARY](state, details) {
    state.contactSummary = details;
  },
  [types.SET_CONTACT_FORM](state) {
    state.formData.firstname = null;
    state.formData.lastname = null;
    state.formData.email = null;
    state.formData.phone = null;
    state.formData.country = null;
    state.formData.product = null;
    state.formData.serial = null;
    state.formData.type = null;
    state.formData.subject = null;
    state.formData.version = null;
    state.formData.description = null;
    state.formData.ip = null;
    state.formData.captcha = null;
  }
};


const module = {
  state,
  mutations
};

export default module;